






import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Tabs from '@/shared/components/Tabs.vue'
import Tab from '@/shared/classes/tab'
import { CompanyRoutes } from '@/shared/router/company'
import permissions from '@/shared/helpers/permissions.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'

@Component({
  components: { Tabs, PageHeader },
  methods: { __ }
})
export default class ReportsIndex extends Vue {
  tabs: Tab[] = [
    new Tab()
      .setTo(CompanyRoutes.reportsIndexTabPaidHours)
      .setTitle(__('company.views.reports.index.tabs.paid-hours.title'))
      .setPermissions([
        permissions.company.reports.paidHours,
      ]),
    new Tab()
      .setTo(CompanyRoutes.reportsIndexTabAllHours)
      .setTitle(__('company.views.reports.index.tabs.all-hours.title'))
      .setPermissions([
        permissions.company.reports.allHours,
      ]),
    new Tab()
      .setTo(CompanyRoutes.reportsIndexTabCollectedHours)
      .setTitle(__('company.views.reports.index.tabs.collected-hours.title'))
      .setPermissions([
        permissions.company.reports.collectedHours,
      ]),
    new Tab()
      .setTo(CompanyRoutes.reportsIndexTabProjects)
      .setTitle(__('company.views.reports.index.tabs.projects.title'))
      .setPermissions([
        permissions.company.reports.projects,
      ])
  ]

  get disabled(): boolean {
    return this.company.uuid === '2242f02c-af85-47f7-ad0b-31fc26e1b502'
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
